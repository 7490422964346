import React, { useMemo } from 'react';

import { IdFilterFields } from '../../../../../types';
import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import { useProductBrandsSelected } from '../../../hooks/useProductBrandsSelected';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

interface ProductsBrandsFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsBrandsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = filters?.[name]?.in || [];

  const { productBrandsSelected } = useProductBrandsSelected({
    selectedIds: filterValue,
    cacheKey: ProductCache.brandsSelectedFilterCacheKey(),
    initialFilters: {
      scope: [FetchCategoriesScopes.FACILITY_MANUFACTURERS],
      id: { in: [] }
    },
    keepPreviousData: true
  });

  const productBrandsSelectedData = useMemo<ProductsBrandsFilterBadgeItem[]>(
    () =>
      productBrandsSelected.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
    [productBrandsSelected]
  );

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.brands.plural}
      items={productBrandsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default ProductsBrandsFilterBadge;
