import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import omit from 'lodash/omit';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsCustomExitNavPath,
  ProductsI18nCustomBaseTitle,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../queries/fetchProductCategories.query';

import { useFinProductCategories } from '../../../hooks/useFinProductCategories';
import { useProductCategories } from '../../../hooks/useProductCategories';

import { ProductsIndexPageCategoriesNavBody } from './components/ProductsIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { ProductCache } from '../../../ProductCache';

interface ProductsIndexPageCategoriesNavProps {
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  customExitNavPath?: ProductsCustomExitNavPath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsIndexPageCategoriesNav({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsIndexPageCategoriesNavProps) {
  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useFinProductCategories({
    cacheKey: ProductCache.notEmptyCategoriesCacheKey(),
    facilityGroupsByProduct: omit(productsFilters, [
      'productCategoryId',
      'productParentCategoryIds'
    ])
  });

  const {
    productCategories: allProductCategories,
    productCategoriesErrorMessage: allProductCategoriesErrorMessage,
    productCategoriesFetched: allProductCategoriesFetched,
    productCategoriesIsPlaceholderData: allProductCategoriesIsPlaceholderData
  } = useProductCategories({
    cacheKey: ProductCache.categoriesCacheKey()
  });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      productCategories?.map((category) => category.parent)
    );

    const parentWithParentCategories = compact(
      parentCategories?.map(
        (category) =>
          find(allProductCategories, { id: category.id }) || category
      )
    );

    return sortBy(
      uniqBy([...productCategories, ...parentWithParentCategories], 'id'),
      'name'
    );
  }, [allProductCategories, productCategories]);

  return (
    <div className="p-4">
      <AlertMessage
        message={
          productCategoriesErrorMessage || allProductCategoriesErrorMessage
        }
      />
      <LoadingSkeleton
        loaded={
          !productCategoriesIsPlaceholderData &&
          productCategoriesFetched &&
          !allProductCategoriesIsPlaceholderData &&
          allProductCategoriesFetched
        }
      >
        <ProductsIndexPageCategoriesNavBody
          productCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          customExitNavPath={customExitNavPath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsIndexPageCategoriesNav;
