import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useProductMaterials } from '../../../hooks/useProductMaterials';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

interface ProductsMaterialsFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsMaterialsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { productMaterials } = useProductMaterials({
    cacheKey: ProductCache.materialsFilterBadgeCacheKey(),
    initialFilters: { scope: [FetchCategoriesScopes.FACILITY_MATERIALS] }
  });

  const ProductsMaterialsFilterBadgeItems = useMemo<
    ProductsMaterialsFilterBadgeItem[]
  >(() => {
    return productMaterials.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productMaterials]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.materials.singular}
      items={ProductsMaterialsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default ProductsMaterialsFilterBadge;
