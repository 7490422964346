import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_PRODUCT_COLORS_FILTERS,
  INITIAL_PRODUCT_COLORS_LIMIT,
  INITIAL_PRODUCT_COLORS_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FETCH_PRODUCT_COLORS_QUERY,
  FetchProductColorsQueryResponse
} from '../../queries/fetchProductColors.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductColorsDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useProductColors({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_COLORS_FILTERS,
  initialSort = INITIAL_PRODUCT_COLORS_SORT,
  initialLimit = INITIAL_PRODUCT_COLORS_LIMIT
}: ProductColorsDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchProductColorsQueryResponse>({
      cacheKey,
      query: FETCH_PRODUCT_COLORS_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    productColors: items,
    productColorsErrorMessage: itemsError,
    productColorsFetched: isFetched,
    productColorsIsPlaceholderData: isPlaceholderData
  };
}

export default useProductColors;
