import { useInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_PRODUCT_MATERIALS_FILTERS,
  INITIAL_PRODUCT_MATERIALS_LIMIT,
  INITIAL_PRODUCT_MATERIALS_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FetchProductMaterialsQueryResponse,
  FETCH_PRODUCT_MATERIALS_QUERY
} from '../../queries/fetchProductMaterials.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductMaterialsDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useProductMaterials({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_MATERIALS_FILTERS,
  initialSort = INITIAL_PRODUCT_MATERIALS_SORT,
  initialLimit = INITIAL_PRODUCT_MATERIALS_LIMIT
}: ProductMaterialsDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    filterItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<FetchProductMaterialsQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_MATERIALS_QUERY,
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    productMaterials: items,
    productMaterialsErrorMessage: itemsError,
    productMaterialsFetched: isFetched,
    productMaterialsIsPlaceholderData: isPlaceholderData,
    productMaterialsFetchingNextPage: isFetchingNextPage,
    productMaterialsFilters: currentFilters,
    hasNextProductMaterialsPage: hasNextPage,
    loadMoreProductMaterials: loadMoreItems,
    productMaterialsFilterSearchValue: currentFilters?.name?.ilike,
    filterProductMaterials: filterItems,
    changeProductMaterialsFilters: changeItemsFilters
  };
}

export default useProductMaterials;
