import { useInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_PRODUCT_BRANDS_FILTERS,
  INITIAL_PRODUCT_BRANDS_LIMIT,
  INITIAL_PRODUCT_BRANDS_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FetchProductBrandsQueryResponse,
  FETCH_PRODUCT_BRANDS_QUERY
} from '../../queries/fetchProductBrands.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductColorsDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useProductBrands({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_BRANDS_FILTERS,
  initialSort = INITIAL_PRODUCT_BRANDS_SORT,
  initialLimit = INITIAL_PRODUCT_BRANDS_LIMIT
}: ProductColorsDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    filterItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<FetchProductBrandsQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_BRANDS_QUERY,
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    productBrands: items,
    productBrandsErrorMessage: itemsError,
    productBrandsFetched: isFetched,
    productBrandsIsPlaceholderData: isPlaceholderData,
    productBrandsFetchingNextPage: isFetchingNextPage,
    productBrandsFilters: currentFilters,
    hasNextProductBrandsPage: hasNextPage,
    loadMoreProductBrands: loadMoreItems,
    productBrandsFilterSearchValue: currentFilters?.name?.ilike,
    filterProductBrands: filterItems,
    changeProductBrandsFilters: changeItemsFilters
  };
}

export default useProductBrands;
