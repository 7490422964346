import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_PRODUCT_TYPES_FILTERS,
  INITIAL_PRODUCT_TYPES_LIMIT,
  INITIAL_PRODUCT_TYPES_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FetchProductTypesQueryResponse,
  FETCH_PRODUCT_TYPES_QUERY
} from '../../queries/fetchProductTypes.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductTypesDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useProductTypes({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_TYPES_FILTERS,
  initialSort = INITIAL_PRODUCT_TYPES_SORT,
  initialLimit = INITIAL_PRODUCT_TYPES_LIMIT
}: ProductTypesDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchProductTypesQueryResponse>({
      cacheKey,
      query: FETCH_PRODUCT_TYPES_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    productTypes: items,
    productTypesErrorMessage: itemsError,
    productTypesFetched: isFetched,
    productTypesIsPlaceholderData: isPlaceholderData
  };
}

export default useProductTypes;
