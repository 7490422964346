import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';
import { IdFilterFields } from '../../../../../types';

import { useProductTypes } from '../../../hooks/useProductTypes';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

interface ProductsTypesFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsTypesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { productTypes } = useProductTypes({
    cacheKey: ProductCache.typesFilterCacheKey(),
    initialFilters: { scope: [FetchCategoriesScopes.PRODUCTS_TYPE] }
  });

  const ProductsTypesFilterBadgeItems = useMemo<
    ProductsTypesFilterBadgeItem[]
  >(() => {
    return productTypes.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productTypes]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.type}
      items={ProductsTypesFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default ProductsTypesFilterBadge;
