import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ClientError } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  FetchProductBrandsSelectedQueryResponse,
  FETCH_PRODUCT_BRANDS_SELECTED_QUERY
} from '../../queries/fetchProductBrandsSelected.query';

import { fetchItems } from '../../../common/hooks/base/reactQuery/baseActions/fetchItems';

import { CategoryID } from '../../../categories/categoriesTypes';

import { parseRequestError } from '../../../../utils/parseRequestError';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesPage,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  INITIAL_PRODUCT_BRANDS_SELECTED_FILTERS,
  INITIAL_PRODUCT_BRANDS_SELECTED_LIMIT,
  INITIAL_PRODUCT_BRANDS_SELECTED_PAGE,
  INITIAL_PRODUCT_BRANDS_SELECTED_SORT
} from '../../productsConstants';

type ProductsBrandsFilterSelectedErrorType = Error | ClientError;

interface ProductsBrandsFilterSelectedResponse {
  categories: {
    nodes: FetchProductBrandsSelectedQueryResponse[];
  };
}

interface ProductBrandsSelectedFilters {
  scope?: string[];
  id?: { in: CategoryID[] };
}

interface ProductBrandsSelectedDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters: ProductBrandsSelectedFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  initialPage?: FetchProductCategoriesPage;
  keepPreviousData?: boolean;
  selectedIds?: CategoryID[];
}

const staleTime = 1000 * 60 * 60;

function useProductBrandsSelected({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_BRANDS_SELECTED_FILTERS,
  initialSort = INITIAL_PRODUCT_BRANDS_SELECTED_SORT,
  initialLimit = INITIAL_PRODUCT_BRANDS_SELECTED_LIMIT,
  initialPage = INITIAL_PRODUCT_BRANDS_SELECTED_PAGE,
  keepPreviousData,
  selectedIds = []
}: ProductBrandsSelectedDefaultOptions) {
  const [currentFilters, setCurrentFilters] =
    useState<ProductBrandsSelectedFilters>(initialFilters);

  const currentParams = {
    filters: currentFilters,
    sort: initialSort,
    page: initialPage,
    limit: initialLimit
  };

  const { data, error } = useQuery<
    ProductsBrandsFilterSelectedResponse,
    ProductsBrandsFilterSelectedErrorType
  >(
    [cacheKey, currentParams],
    () =>
      fetchItems({
        query: FETCH_PRODUCT_BRANDS_SELECTED_QUERY,
        ...currentParams
      }),
    {
      enabled: !isEmpty(currentFilters?.id?.in),
      staleTime,
      keepPreviousData
    }
  );

  const queryResponseValue = data?.categories;

  const items =
    queryResponseValue?.nodes || <FetchProductBrandsSelectedQueryResponse[]>[];

  useEffect(() => {
    if (isEmpty(selectedIds) && isEmpty(currentFilters?.id?.in)) {
      return;
    }

    if (!isEqual(selectedIds, currentFilters.id?.in)) {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        id: { in: selectedIds }
      }));
    }
  }, [selectedIds, currentFilters, setCurrentFilters]);

  return {
    productBrandsSelected: items,
    productsBrandsSelectedErrorMessage: parseRequestError(error)
  };
}

export default useProductBrandsSelected;
