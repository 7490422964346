import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters
} from '../../productsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  FETCH_PRODUCT_CATEGORIES_QUERY,
  FetchProductCategoriesQueryResponse
} from '../../queries/fetchProductCategories.query';

const defaultInitialFilters = {
  scope: ['facility_groups']
};
const initialSort = ['NAME_ASC'];
const options = {
  staleTime: 1000 * 60 * 60
};

const scope = 'categories';

interface ProductCategoriesOptions {
  cacheKey?: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchProductCategoriesFilters;
}

function useProductCategories({
  cacheKey,
  addInitialFilters
}: ProductCategoriesOptions = {}) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchProductCategoriesQueryResponse>({
      query: FETCH_PRODUCT_CATEGORIES_QUERY,
      cacheKey,
      initialFilters,
      initialSort,
      initialLimit: 1000,
      scope,
      options
    });

  return {
    productCategories: items,
    productCategoriesErrorMessage: itemsError,
    productCategoriesFetched: isFetched,
    productCategoriesIsPlaceholderData: isPlaceholderData
  };
}

export default useProductCategories;
