import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_PRODUCT_STYLES_FILTERS,
  INITIAL_PRODUCT_STYLES_LIMIT,
  INITIAL_PRODUCT_STYLES_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FetchProductStylesQueryResponse,
  FETCH_PRODUCT_STYLES_QUERY
} from '../../queries/fetchProductStyles.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductStylesDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useProductStyles({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_STYLES_FILTERS,
  initialSort = INITIAL_PRODUCT_STYLES_SORT,
  initialLimit = INITIAL_PRODUCT_STYLES_LIMIT
}: ProductStylesDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchProductStylesQueryResponse>({
      cacheKey,
      query: FETCH_PRODUCT_STYLES_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    productStyles: items,
    productStylesErrorMessage: itemsError,
    productStylesFetched: isFetched,
    productStylesIsPlaceholderData: isPlaceholderData
  };
}

export default useProductStyles;
