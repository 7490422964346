import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useProductColors } from '../../../hooks/useProductColors';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

interface ProductsColorsFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsColorsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { productColors } = useProductColors({
    cacheKey: ProductCache.colorsFilterCacheKey(),
    initialFilters: { scope: [FetchCategoriesScopes.FACILITY_COLORS] }
  });

  const ProductsColorsFilterBadgeItems = useMemo<
    ProductsColorsFilterBadgeItem[]
  >(() => {
    return productColors.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productColors]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.colors.singular}
      items={ProductsColorsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default ProductsColorsFilterBadge;
