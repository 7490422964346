import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_PRODUCT_SEASONAL_FILTERS,
  INITIAL_PRODUCT_SEASONAL_LIMIT,
  INITIAL_PRODUCT_SEASONAL_SORT
} from '../../productsConstants';

import {
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesFilters,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import {
  FetchProductSeasonalQueryResponse,
  FETCH_PRODUCT_SEASONAL_QUERY
} from '../../queries/fetchProductSeasonal.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductSeasonalDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useProductSeasonal({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_SEASONAL_FILTERS,
  initialSort = INITIAL_PRODUCT_SEASONAL_SORT,
  initialLimit = INITIAL_PRODUCT_SEASONAL_LIMIT
}: ProductSeasonalDefaultOptions) {
  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchProductSeasonalQueryResponse>({
      cacheKey,
      query: FETCH_PRODUCT_SEASONAL_QUERY,
      initialFilters,
      initialSort,
      initialLimit,
      scope,
      options
    });

  return {
    productSeasonal: items,
    productSeasonalErrorMessage: itemsError,
    productSeasonalFetched: isFetched,
    productSeasonalIsPlaceholderData: isPlaceholderData
  };
}

export default useProductSeasonal;
